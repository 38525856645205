import { Asset } from './asset.model';
import { SuperModel } from './super-model.model';

export class Product extends SuperModel {
  eu_item: string;
  ean: string;
  title: string;
  shadeName: string;
  segment: {
    description: string;
  };
  frontImage: {
    image: Asset;
  }[];
  primaryPackaging: {
    material: string;
    sortingDetails: string;
    material_fr: string;
    sortingDetails_fr: string;
    material_it: string;
    sortingDetails_it: string;
  }[];
  secondaryPackaging: {
    material: string;
    sortingDetails: string;
    material_fr: string;
    sortingDetails_fr: string;
    material_it: string;
    sortingDetails_it: string;
  }[];

  constructor(data: any = {}) {
    super(data);

    if (data.frontImage) {
      this.frontImage = data.frontImage.map(item => ({ ...item, image: new Asset(item.image) }));
    } else {
      this.frontImage = [];
    }

    if (!data.primaryPackaging) {
      this.primaryPackaging = [];
    }

    if (!data.secondaryPackaging) {
      this.secondaryPackaging = [];
    }
  }

  // We do not include readonly fields like inventory, label, description
  get serialised() {
    return {
      id: this.id,
      eu_item: this.eu_item,
      ean: this.ean,
      title: this.title,
      shadeName: this.shadeName,
      segment: this.segment,
      frontImage: this.frontImage,
      primaryPackaging: this.primaryPackaging,
      secondaryPackaging: this.secondaryPackaging,
    };
  }
}
