<div class="slideshow-container position-relative m-auto">
  <div #slides>
    <div class="fade"
      [ngStyle]="{'display': activeIndex === i ? 'block' : 'none'}"
      *ngFor="let image of images; let i = index;">
      <bitf-image class="w-100"
        placeholderSrc="/assets/img/placeholder-image.png"
        [sources]="{
        'xs':{'1x':image?.mobile, '2x':image?.mobile_15 },
        'lg':{'1x':image?.desktop, '2x':image?.desktop_15 }
      }">
      </bitf-image>
    </div>
    <!-- Next and previous buttons -->
    <a class="prev"
      *ngIf="images?.length > 1"
      (click)="showPrevSlide()">❮</a>
    <a class="next"
      *ngIf="images?.length > 1"
      (click)="showNextSlide()">❯</a>
  </div>

  <div class="dots"
    *ngIf="images?.length > 1"
    #dots>
    <span class="dot"
      [ngClass]="activeIndex === i ? 'active' : ''"
      (click)="showSlide(i)"
      *ngFor="let image of images; let i = index;"></span>
  </div>
</div>