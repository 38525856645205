import {
  IBitfGraphQlResponse,
  IBitfGraphQlRequest,
  IBitfApiPagination,
  IBitfGraphQlResponseMapper,
} from '@interfaces';
import { modelsMap } from '@common/parsers/models-mapper/models-mapper.strategy';

export abstract class BitfGraphQlResponseMapper implements IBitfGraphQlResponseMapper {
  constructor() {}
  // NOTE query abstract methods
  protected abstract isResponseArray(response): boolean;
  protected abstract getContentAsArray<T>(response, model: T): T[];
  protected abstract calculatePagination(
    requestParams: IBitfGraphQlRequest,
    response: any
  ): IBitfApiPagination;

  // NOTE: mutations abstract methods
  protected abstract extractMutationResponseAndMetadata({
    originalBody,
    responseEnveloped,
  }: {
    originalBody: any;
    responseEnveloped?: IBitfGraphQlResponse<any>;
  }): void;

  mapQueryResponse<T>(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>
  ): IBitfGraphQlResponse<T> {
    // CONTENT
    this.mapContent<T>(requestParams, responseEnveloped, true);

    // SORTING
    this.mapQuerySorting(requestParams, responseEnveloped);

    // PAGINATION
    this.mapQueryPagination(requestParams, responseEnveloped);

    return responseEnveloped as IBitfGraphQlResponse<T>;
  }

  mapMutationResponse<T>(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>
  ): IBitfGraphQlResponse<T> {
    // CONTENT
    this.mapContent(requestParams, responseEnveloped, false);

    return responseEnveloped as IBitfGraphQlResponse<T>;
  }

  private mapContent<T>(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>,
    isQuery: boolean
  ) {
    if (responseEnveloped.originalBody === undefined || responseEnveloped.originalBody === null) {
      return undefined;
    }

    const { modelMapper } = requestParams;
    if (modelMapper) {
      const response = this.extractResponseAndMetadata(responseEnveloped, modelMapper, isQuery);
      if (response) {
        // NOTE: we've that model in the response we can create the object
        const model = this.getModel(modelMapper);
        if (model) {
          // NOTE: single response entity
          if (this.isResponseArray(response)) {
            // NOTE: response with array of objects
            responseEnveloped.content = this.getContentAsArray<T>(response, model);
          } else {
            // NOTE: Response with a single object
            responseEnveloped.content = new model(response.data || response);
          }
        }
      }
    } else {
      // NOTE: this is a delete mutation
    }
  }

  private mapQuerySorting(requestParams: IBitfGraphQlRequest, responseEnveloped: IBitfGraphQlResponse<any>) {
    const { modelMapper } = requestParams;
    const response = this.extractResponseAndMetadata(responseEnveloped, modelMapper, true);
    if (response) {
      responseEnveloped.sorting = requestParams.sorting;
    }
  }

  private mapQueryPagination(
    requestParams: IBitfGraphQlRequest,
    responseEnveloped: IBitfGraphQlResponse<any>
  ) {
    const { modelMapper } = requestParams;
    const response = this.extractResponseAndMetadata(responseEnveloped, modelMapper, true);
    if (response && (requestParams.size || requestParams.page)) {
      responseEnveloped.pagination = this.calculatePagination(requestParams, response);
    }
  }

  private extractResponseAndMetadata(
    responseEnveloped: IBitfGraphQlResponse<any>,
    modelMapper: string,
    isQuery: boolean
  ) {
    let response: any;
    // NOTE: if there is not the model specified in the request, could be a multi entity request so we'll not
    // find the model specified as prop ex modelMapper = Filter but in the query there is
    // colors, brands etc...
    const originalBody = responseEnveloped.originalBody[modelMapper] || responseEnveloped.originalBody;
    if (!originalBody) {
      // NOTE: not logged in UI because this is a dev error in model strategy settings
      throw new Error(`responseEnveloped[${modelMapper}] not found`);
    }
    if (isQuery) {
      response = originalBody;
    } else {
      response = this.extractMutationResponseAndMetadata({ originalBody, responseEnveloped });
    }

    return response;
  }

  private getModel(modelMapper: string) {
    const model = modelsMap.get(modelMapper);
    if (!model) {
      console.error(`model for modelMapper: ${modelMapper} not found`);
    }
    return model;
  }
}
