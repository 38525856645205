import { Injectable, Injector } from '@angular/core';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest } from '@interfaces';
import { Folder } from '@common/core/models';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class AssetsService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getAssetFolder(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'getAssetFolder';
    requestParams.query = gql`
      {
        getAssetFolder(fullpath: "/Site/Slider") {
          children {
            ... on asset {
              filename
              desktop: fullpath(thumbnail: "slideshow_desktop")
              desktop_15: fullpath(thumbnail: "slideshow_desktop_15")
              mobile: fullpath(thumbnail: "slideshow_mobile")
              mobile_15: fullpath(thumbnail: "slideshow_mobile_15")
              mimetype
            }
          }
        }
      }
    `;

    return super.query<Folder>(requestParams);
  }
}
