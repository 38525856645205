import { environment } from '@env/environment';
import { SuperModel } from './super-model.model';

export class Asset extends SuperModel {
  filename: string;
  desktop: string;
  desktop_15: string;
  mobile: string;
  mobile_15: string;
  mimetype: string;

  constructor(data = {}) {
    super(data);

    if (this.desktop) {
      this.desktop = new URL(this.desktop, environment.apiUrl).href;
    }

    if (this.desktop_15) {
      this.desktop_15 = new URL(this.desktop_15, environment.apiUrl).href;
    }

    if (this.mobile) {
      this.mobile = new URL(this.mobile, environment.apiUrl).href;
    }

    if (this.mobile_15) {
      this.mobile_15 = new URL(this.mobile_15, environment.apiUrl).href;
    }
  }

  // We do not include readonly fields like inventory, label, description
  get serialised() {
    return {
      id: this.id,
      filename: this.filename,
      desktop: this.desktop,
      desktop_15: this.desktop_15,
      mobile: this.mobile,
      mobile_15: this.mobile_15,
      mimetype: this.mimetype,
    };
  }
}
