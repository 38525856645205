import { BitfGraphQlPaginationApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-graph-ql-api-call-state';
import { BitfSearchApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-search-api-request-part.builder';

import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.PRODUCT_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart(
        EApiRequestPartKeys.PAGINATION,
        { page: 1, size: 30 },
        { pageIndexOffset: 1 }
      ),
      new BitfSearchApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
