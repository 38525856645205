import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfMatSearchComponent } from '@common/libs/bitforce/core/components/ui/search/material/bitf-mat-search.component';
import { BitfMatSortComponent } from '@common/libs/bitforce/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { BrandBlockComponent } from './brand-block/brand-block.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { BitfImageComponent } from '@common/libs/bitforce/core/components/ui/image/bitf-image/bitf-image.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { GlobalFootprintMapComponent } from './global-footprint-map/global-footprint-map.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { GlitterImageComponent } from './glitter-image/glitter-image.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES, FontAwesomeModule];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfMatSearchComponent,
  BitfMatSortComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  BitfMatLoaderComponent,
  BitfImageComponent,
  HeaderComponent,
  SidenavComponent,
  FooterComponent,
  BrandBlockComponent,
  SlideshowComponent,
  GlobalFootprintMapComponent,
  PageHeaderComponent,
  SectionTitleComponent,
  GlitterImageComponent,
];
const SHARED_DIRECTIVES = [];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faLinkedin, faFacebook, faInstagram, faTiktok);
  }
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
