import { Component, Input, OnInit } from '@angular/core';
import { Asset } from '@common/core/models';

@Component({
  selector: 'mks-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
})
export class SlideshowComponent implements OnInit {
  @Input() images: Asset[];
  activeIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  showPrevSlide() {
    if (this.activeIndex) {
      this.activeIndex--;
    } else {
      this.activeIndex = this.images.length - 1;
    }
  }

  showNextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.images.length;
  }

  showSlide(n) {
    this.activeIndex = n;
  }
}
