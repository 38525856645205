<div class="map-section">
  <div class="map-section__content">
    <div class="map-section__sm-title"
      *ngIf="showTitle">Distributed in <span>80+</span> countries with 15 key global
      locations.</div>
    <div class="map-section__img-wrap">
      <div class="map-section__map map">
        <div class="map__body">
          <div class="map__item"
            [ngClass]="{'map__break': i.break, 'map__item': !i.break}"
            *ngFor="let i of mapItems; let index = index"
            [attr.index]="index"></div>

        </div>
        <div class="map__markers">
          <ng-container *ngFor="let marker of markers">
            <a class="map__marker"
              [ngStyle]="{
                top: (isMobile | async) ? marker.position.mobileTop + 'px' : marker.position.top + 'px',
                left: (isMobile | async) ? marker.position.mobileLeft + 'px' : marker.position.left + 'px'
              }"
              tabindex="1">
              <div class="map__marker-content desktop"
                *ngIf="showMarkers">
                <h4>{{marker.city}}, {{marker.country}}</h4>
                <strong [innerHTML]="marker.title"></strong>
                <p [innerHTML]="marker.subtitle"></p>
              </div>
            </a>
          </ng-container>

        </div>

      </div>
    </div>
    <div *ngIf="showContent"
      class="map-section__list">
      <div class="map-section__item">
        <h4 class="map-section__item-title">
          Global Headquarters: </h4>
        <div class="map-section__item-name"
          *ngFor="let item of contentData.internationalHeadquarters">{{item}}</div>
      </div>
      <div class="map-section__item">
        <h4 class="map-section__item-title">
          International Headquarters: </h4>
        <div class="map-section__item-name"
          *ngFor="let item of contentData.internationalHeadquarters">{{item}}</div>
      </div>
      <div class="map-section__item">
        <h4 class="map-section__item-title">
          Manufacturing Centers: </h4>
        <div class="map-section__item-name"
          *ngFor="let item of contentData.manufactoringCenters">{{item}}</div>
      </div>
      <div class="map-section__item">
        <h4 class="map-section__item-title">
          Distribution Centers: </h4>
        <div class="map-section__item-name"
          *ngFor="let item of contentData.distributionCenters">{{item}}</div>
      </div>
    </div>
    <div class="map-section__text">
      <p>Markwins beauty brands are
        distributed in over 80 countries
        worldwide. Headquartered in sunny
        Los Angeles, we have locations all
        over the world, including seven global
        distribution centers, international
        headquarters in Milan, and three
        manufacturing facilities in California,
        Mexico, and China.</p>
    </div>
  </div>
</div>