<mat-form-field appearance="outline"
  class="w-100"
  [ngStyle]="{'font-size': fontSize}"
  [ngClass]="{'toolbar-layout': isInToolbar}">
  <mat-label>{{placeholder}}</mat-label>
  <input matInput
    [formControl]="formControl"
    [placeholder]="placeholder">
  <mat-icon matPrefix
    *ngIf="hasSearchIcon">search</mat-icon>

  <button mat-button
    mat-icon-button
    matSuffix
    (click)="clearSearchField()"
    *ngIf="hasCancelButton && formControl.value">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>