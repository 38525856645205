import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { StoreService } from '@common/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IMapMarker {
  city: string;
  country: string;
  title?: string;
  subtitle?: string;
  position: {
    top: number;
    left: number;
    mobileTop: number;
    mobileLeft: number;
  };
}

@Component({
  selector: 'mks-global-footprint-map',
  templateUrl: './global-footprint-map.component.html',
  styleUrls: ['./global-footprint-map.component.scss'],
})
export class GlobalFootprintMapComponent implements OnInit {
  @Input() showContent = false;
  @Input() showTitle = false;
  @Input() showMarkers = false;
  mapItems = new Array(64).fill({ break: false }).map((item, index) => {
    if (index === 27) {
      return { break: true };
    } else {
      return item;
    }
  });

  markers: IMapMarker[] = [
    {
      city: 'Los Angeles, California',
      country: 'USA',
      title: 'MFG & Distribution',
      subtitle: 'Compounding & MFG<br>Distribution NA & SA<br>New Product R&D',
      position: {
        top: 143,
        left: 88,
        mobileTop: 117,
        mobileLeft: 72,
      },
    },
    {
      city: 'Tijuana',
      country: 'Mexico',
      title: 'MFG & Distribution',
      subtitle: 'Compounding & MFG<br>New Product R&D',
      position: {
        top: 154,
        left: 99,
        mobileTop: 126,
        mobileLeft: 81,
      },
    },
    {
      city: 'Minneapolis',
      country: 'Minnesota',
      title: '',
      subtitle: '',
      position: {
        top: 99,
        left: 143,
        mobileTop: 81,
        mobileLeft: 117,
      },
    },
    {
      city: 'Bentonville',
      country: 'AR',
      title: '',
      subtitle: '',
      position: {
        top: 143,
        left: 143,
        mobileTop: 117,
        mobileLeft: 117,
      },
    },
    {
      city: 'Chicago',
      country: 'Illinois',
      title: '',
      subtitle: '',
      position: {
        top: 110,
        left: 154,
        mobileTop: 90,
        mobileLeft: 126,
      },
    },
    {
      city: 'Rhode Island',
      country: 'Rl',
      title: '',
      subtitle: '',
      position: {
        top: 132,
        left: 176,
        mobileTop: 108,
        mobileLeft: 144,
      },
    },
    {
      city: 'London',
      country: '',
      title: '',
      subtitle: 'Distribution UK<br>Sales & Trade Mktg',
      position: {
        top: 99,
        left: 319,
        mobileTop: 410,
        mobileLeft: 18,
      },
    },
    {
      city: 'Barcelona',
      country: '',
      title: '',
      subtitle: 'EMEA Dist & Logistics',
      position: {
        top: 143,
        left: 319,
        mobileTop: 446,
        mobileLeft: 18,
      },
    },
    {
      city: 'Paris',
      country: 'France',
      title: '',
      subtitle: '',
      position: {
        top: 110,
        left: 330,
        mobileTop: 419,
        mobileLeft: 27,
      },
    },
    {
      city: 'Milan',
      country: 'Italy',
      title: 'International Headquarters',
      subtitle: 'Sales & Trade Mktg<br>Business Development',
      position: {
        top: 132,
        left: 352,
        mobileTop: 437,
        mobileLeft: 45,
      },
    },
    {
      city: 'HONG KONG',
      country: '',
      title: 'APAC HQ',
      subtitle: 'Sales & Trade Mktg<br>Business Development',
      position: {
        top: 132,
        left: 572,
        mobileTop: 437,
        mobileLeft: 225,
      },
    },
    {
      city: 'Shenzhen',
      country: 'China',
      title: '',
      subtitle: '',
      position: {
        top: 132,
        left: 583,
        mobileTop: 437,
        mobileLeft: 234,
      },
    },
    {
      city: 'Shanghai',
      country: 'China',
      title: '',
      subtitle: '',
      position: {
        top: 110,
        left: 583,
        mobileTop: 419,
        mobileLeft: 234,
      },
    },
    {
      city: 'Montevideo',
      country: 'Uruguay',
      title: '',
      subtitle: '',
      position: {
        top: 297,
        left: 198,
        mobileTop: 243,
        mobileLeft: 162,
      },
    },
    {
      city: 'Toronto',
      country: 'Canada',
      title: '',
      subtitle: '',
      position: {
        top: 88,
        left: 176,
        mobileTop: 72,
        mobileLeft: 144,
      },
    },
  ];

  contentData = {
    globalHeadquarters: ['Los Angeles, California, USA'],
    internationalHeadquarters: ['Milan, Italy', 'Hong Kong, China', 'Montevideo, Uruguay'],
    manufactoringCenters: [
      'Los Angeles, California, USA',
      'Shanghai, China',
      'Shenzhen, China',
      'Tijuana, Mexico',
    ],
    distributionCenters: ['USA', 'Canada', 'Mexico', 'China', 'Spain', 'Poland', 'UK', 'Australia'],
  };

  get isMobile(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['screen and (max-width: 960px)'])
      .pipe(map(result => result.matches));
  }

  constructor(public breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {}
}
