import { Injectable, ViewContainerRef, ComponentRef, Component } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { IBitfSidenavContent, IBitfCloseEvent } from '@interfaces';
import { Subject } from 'rxjs';
import { EBitfCloseEventStatus } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class BitfMatSidenavGlobalService {
  sidenav: MatSidenav;
  sidenavContent: ViewContainerRef;
  initialised = false;

  componentRef: ComponentRef<Component> = null;
  subject: Subject<IBitfCloseEvent<unknown>> = new Subject<IBitfCloseEvent<unknown>>();

  constructor() {}

  init(sidenav: MatSidenav, sidenavContent: ViewContainerRef) {
    this.sidenav = sidenav;
    this.sidenavContent = sidenavContent;
    this.initialised = true;

    this.sidenav.closedStart.subscribe(() => this.close());
  }

  open(data: IBitfSidenavContent) {
    if (!this.initialised) {
      throw Error('sidenav: component not initialised yet!');
    }

    this.sidenavContent.clear();
    if (data.componentFactory) {
      this.componentRef = this.sidenavContent.createComponent(data.componentFactory);
      Object.assign(this.componentRef.instance, data.componentData || {});
    } else {
      throw Error('sidenav: Component factory not initialised');
    }

    Object.assign(this.sidenav, this.getDefaultOptions(), data.sidenavOptions);
    this.sidenav.open();
    this.completeAllSubject();

    return this.subject;
  }

  private getDefaultOptions() {
    return {
      mode: 'over',
      position: 'end',
      disableClose: true,
    };
  }

  close<T>(sidenavEvent: IBitfCloseEvent<T> = { status: EBitfCloseEventStatus.CLOSE }) {
    this.componentRef.destroy();
    this.sidenavContent.clear();
    this.sidenav.close();
    this.subject.next(sidenavEvent);
  }

  completeAllSubject() {
    this.subject.observers.forEach(observer => observer.complete());
  }
}
