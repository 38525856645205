import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'mks-glitter-image',
  templateUrl: './glitter-image.component.html',
  styleUrls: ['./glitter-image.component.scss'],
})
export class GlitterImageComponent implements OnInit {
  @Input() src: string;
  @Input() alt: string;
  stars: any[] = [];

  ngOnInit(): void {}

  onMouseEnter() {
    this.stars = [];
    for (let i = 0; i < 20; i++) {
      const sizes = ['small', 'medium'];
      const size = sizes[Math.floor(Math.random() * sizes.length)];
      const directions = [-1, 1];
      const translate = {
        x: directions[Math.floor(Math.random() * directions.length)] * (Math.random() * 50),
        y: directions[Math.floor(Math.random() * directions.length)] * (Math.random() * 50),
      };
      this.stars.push({
        class: size,
        style: {
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          '--translate-x': `${translate.x}px`,
          '--translate-y': `${translate.y}px`,
          animation: `glitter ${Math.random() * 3 + 2}s infinite`,
        },
      });
    }
  }

  onMouseLeave() {
    this.stars = [];
  }
}
