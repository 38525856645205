import { Injector } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { concat, forkJoin, merge, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, takeLast, tap } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';
import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { BitfGraphQlHelper } from './bitf-graph-ql.helper';
import { StoreService, LoaderService } from '@services';

export abstract class BitfGraphQlService {
  protected apollo: Apollo;
  protected storeService: StoreService;
  protected helper: BitfGraphQlHelper;
  protected loaderService: LoaderService;
  protected bitfErrorHandlerService: BitfErrorHandlerService;

  constructor(protected injector: Injector) {
    this.apollo = this.injector.get<Apollo>(Apollo);
    this.loaderService = this.injector.get<LoaderService>(LoaderService);
    this.storeService = this.injector.get<StoreService>(StoreService);
    this.bitfErrorHandlerService = this.injector.get<BitfErrorHandlerService>(BitfErrorHandlerService);

    this.initHelper();
  }

  public getThrottledObservables(observables: Observable<any>[], concurrency = 5) {
    if (!observables.length) {
      return of(undefined);
    }
    const responses = [];
    const errors = [];
    const groupedObservables = observables
      // NOTE: We are catching the error here to avoid to break the chain in case one OBS
      // will generate an error
      .map(obs =>
        obs.pipe(
          catchError(error => {
            this.bitfErrorHandlerService.handle(error);
            errors.push(error);
            return of(true);
          }),
          tap(response => {
            responses.push(response);
          })
        )
      )
      .reduce((r, e, i) => (i % concurrency ? r[r.length - 1].push(e) : r.push([e])) && r, []);
    const forkJoinedObservables = groupedObservables.map(groupedObservable => forkJoin(groupedObservable));

    const checkPipe$ = of(true).pipe(
      switchMap(() => {
        if (errors.length) {
          return throwError({ errors, responses });
        }
        return of(responses);
      })
    );

    // NOTE: the concat will fire the throttling [[...],[...]] with one emit per fork then we want
    // to flat all the responses in only one final emit with the outcome of all the calls
    return concat(...forkJoinedObservables).pipe(
      takeLast(1),
      switchMap(() => checkPipe$)
    );
  }

  protected query<T>(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<T>> {
    const request = this.helper.mapQueryRequestParams(requestParams);
    return this.apollo.query(request).pipe(
      // NOTE: Catch Apollo errors, generate a uiMessage and forward the error
      catchError(apolloError => this.helper.handleApolloErrors$(apolloError)),
      // NOTE: Generate the IBitfGraphQlEnvelopeMapper response envelope
      // in this stage we populate the uiMessages if there are some errors
      map(graphQlResponse => this.helper.mapEnvelope(requestParams, graphQlResponse)),
      // NOTE: If there are any uiMessages we show them to the user
      // If some uiMessages are type === 'Error' we raise an exception to break the
      // observable chain
      switchMap(envelopedResponse => this.helper.handleUiMessages$(envelopedResponse)),
      // NOTE: If the chain reach this point we can map the response which doesn't contains any errors
      map(envelopedResponse => this.helper.mapQueryResponse<T>(requestParams, envelopedResponse)),
      finalize(() => !requestParams.disableHideLoader && this.loaderService.hide())
    );
  }

  protected mutate<T>(
    requestParams: IBitfGraphQlRequest,
    mutateThisObjectOptimistically?: any
  ): Observable<IBitfGraphQlResponse<T>> {
    // NOTE: provide a subject to emit an event in case of Apollo optimistic update is enabled
    // const optimisticUpdate$ = new Subject<T>();
    // requestParams.optimisticUpdate$ = optimisticUpdate$;

    // NOTE: if mutateThisObjectOptimistically is passed we handle the optimistic update
    // applying the mutation straight and reverting it in case of errors
    let backupProps = {};
    if (mutateThisObjectOptimistically) {
      backupProps = this.extractPropsToMutate(mutateThisObjectOptimistically, requestParams.body);
      Object.assign(mutateThisObjectOptimistically, requestParams.body);
    }

    const request = this.helper.mapMutationRequestParams(requestParams);
    return merge(
      // optimisticUpdate$.pipe(
      //   //NOTE: we need only the optimistic update because the second is the
      //   // real response which return from apollo.mutate observable too.
      //   take(1)
      // ),
      this.apollo.mutate(request)
    ).pipe(
      catchError(apolloError => this.helper.handleApolloErrors$(apolloError)),
      map(graphQlResponse => this.helper.mapEnvelope(requestParams, graphQlResponse)),
      map(envelopedResponse => this.helper.mapMutationResponse<T>(requestParams, envelopedResponse)),
      switchMap(envelopedResponse => this.helper.handleUiMessages$(envelopedResponse)),
      // NOTE: At this stage we'll have exception raised for both apolloError and query error
      catchError(error =>
        this.helper.revertOptimisticUpdates$(error, mutateThisObjectOptimistically, backupProps)
      ),
      finalize(() => !requestParams.disableHideLoader && this.loaderService.hide())
    );
  }

  // TODO
  protected uploadFile() {}

  private extractPropsToMutate(originalObject: any, newObject: any) {
    const backupProps = {};
    Object.keys(newObject).forEach(key => (backupProps[key] = originalObject[key]));
    return backupProps;
  }

  private initHelper() {
    this.helper = new BitfGraphQlHelper(this.storeService);
  }
}

// new items
// - apollo config with no-cache
// - graph ql interface
// - super graph ql service
// - parsers interface
// - parsers (request / response / envelope) - (FIXME) envelope non va interceptor
// - graph ql helper
// - single query / multiple query(WIP ma da dismettere) / apollo native batch (TODO)
// - fragments
// - mutations / custom mutation serialiser (???) / optimistic updates
// - file upload (TODO)
// - pagination (bitf-mat-paginator) / sorting (options/ single) / search input / form filters
// - api call state mappers
// - http errors, gestiti da BitfApiErrorsInterceptor (FIXME bypassati dal catchError)
// - query errors gestiti da handleQueryErrors$
// - apollo error gestiti da catchError
// - ui messages listener aggiunto graphQl
// - ui messages (TODO)
// - retry (prob in apollo) (TODO)
// - gestione auth, attivato BitfOAuthInterceptor (TBT) + 401 nell BitfApiErrorsInterceptor
// - rimossa persistenza dallo storage (store-storage)
