import { Asset } from './asset.model';
import { SuperModel } from './super-model.model';

export class Brand extends SuperModel {
  code: string;
  name: string;
  subtitle: string;
  description: string;
  logo_white: Asset;
  logo_black: Asset;
  image: Asset;

  constructor(data: any = {}) {
    super(data);

    if (data.logo_white) {
      this.logo_white = new Asset(data.logo_white);
    }
    if (data.logo_black) {
      this.logo_black = new Asset(data.logo_black);
    }
    if (data.image) {
      this.image = new Asset(data.image);
    }
  }

  // We do not include readonly fields like inventory, label, description
  get serialised() {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      subtitle: this.subtitle,
      description: this.description,
      logo_white: this.logo_white,
      logo_black: this.logo_black,
      image: this.image,
    };
  }
}
