import { BITF_CONFIGS as COMMON_BITF_CONFIGS } from '@common/configs/common-bitf.configs';

const WEB_BITF_CONFIGS = {
  urls: { homePageUrl: '/', signInUrl: '/sign-in', signOutUrl: '/sign-in' },
  pwa: { serviceWorker: { checkUpdateInterval: 30000 }, installPrompt: { askAgainAfterNDays: 10 } },
  social: {
    linkedin: 'https://ru.linkedin.com/company/markwins-international',
    instagram: 'https://www.instagram.com/wetnwildeu/',
    facebook: 'https://www.facebook.com/wetnwildeurope/',
    tiktok: 'https://www.tiktok.com/@wetnwildeu',
  },
};

export const BITF_CONFIGS = Object.assign(COMMON_BITF_CONFIGS, WEB_BITF_CONFIGS);
