import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DefaultUrlSerializer,
  DetachedRouteHandle,
  RouteReuseStrategy,
  RouterModule,
  UrlSegment,
  UrlSerializer,
  UrlTree,
} from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';

import { SharedModule } from '@shared/shared.module';
import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded } from './module-config';
import { NotFoundComponent } from './components/not-found/not-found.component';

// NOTE: custom url serializer to replace '-' with ' ' and vice versa for brand/:brandName/:brandId route
export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const urlTree = super.parse(url);
    const segments: UrlSegment[] = urlTree.root.children['primary']?.segments;
    if (segments && segments.length >= 3 && segments[0].path === 'brand') {
      segments[1].path = segments[1].path.replace(/-/g, ' ');
      if (segments.length > 4) {
        segments[4].path = segments[4].path.replace(/-/g, ' ');
      }
    }
    return urlTree;
  }

  serialize(tree: UrlTree): string {
    const segments: UrlSegment[] = tree.root.children['primary']?.segments;
    if (segments && segments.length >= 3 && segments[0].path === 'brand') {
      segments[1].path = segments[1].path.replace(/ /g, '-');
      if (segments.length > 4) {
        segments[4].path = segments[4].path.replace(/ /g, '-');
      }
    }
    return super.serialize(tree);
  }
}

const EXPORTS_COMPONENTS = [NotFoundComponent];
const COMPONENTS = [];

@NgModule({
  imports: [CommonCoreModule, RouterModule, SharedModule.forRoot(), PortalModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
