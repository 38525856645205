import { Component, OnInit } from '@angular/core';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@common/enums';
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { StoreService } from '@web/core/services';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { BITF_CONFIGS } from '@web/configs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  configs = BITF_CONFIGS;
  constructor(public storeService: StoreService, public bitfMatSidenavService: BitfMatSidenavService) {}

  ngOnInit(): void {}

  onToggleMenuButton() {
    this.bitfMatSidenavService
      .open({
        component: SidenavComponent,
        componentData: {
          brands: this.storeService.store.brands,
        },
        sidenavOptions: {
          mode: EBitfSidenavMode.OVER,
          position: EBitfSidenavPosition.LEFT,
        },
      })
      .subscribe(() => {});
  }
}
