<div class="container-title">
  <div class="container">
    <div class="row d-none d-sm-block">
      <div class="col-24 row justify-content-between align-items-center">
        <h1>{{title}}</h1>
        <a [routerLink]="buttonRightRoute"
          *ngIf="buttonRightRoute"
          class="sparkley button white-btn btn-header">{{buttonRightText}}</a>
      </div>
    </div>
    <div class="row d-block d-sm-none">
      <div class="col-24">
        <h1>{{title}}</h1>
        <div class=" w-100 d-flex justify-content-end pt-3">

          <a [routerLink]="buttonRightRoute"
            *ngIf="buttonRightRoute"
            class="sparkley button button-right-header-mobile white-btn btn-header">
            <u>{{buttonRightText}}</u></a>
        </div>
      </div>
    </div>
  </div>
</div>