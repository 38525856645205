<footer class="footer d-flex flex-column align-items-center justify-content-center">
  <img class="logo"
    src="assets/img/logo_footer.svg" />

  <div class="container">
    <div class="row">
      <div class="col-24 footer-container">
        <!-- About -->
        <li class="footer-menu-title">
          <a tabindex="
            1"
            target="_self">
            About </a>

          <ul class="footer-list">
            <li class="items-of-list">
              <a tabindex="1"
                class="footer-menu-link"
                [routerLink]="['about','who-we-are']"
                target="_self">
                Who We Are </a>
            </li>
            <li class="items-of-list">
              <a tabindex="1"
                class="footer-menu-link"
                [routerLink]="['about','our-global-footprint']"
                target="_self">
                Our Global Footprint </a>
            </li>
            <li class="items-of-list">
              <a tabindex="1"
                class="footer-menu-link"
                [routerLink]="['about','culture-values']"
                target="_self">
                Culture &amp; Values </a>
            </li>
          </ul>
        </li>
        <!-- Brands -->
        <li class="w-100 footer-menu-title">
          <a tabindex="1"
            [routerLink]="['/our-brands']"
            target="_self">
            Our Brands </a>
          <ul class="footer-list">
            <li class="items-of-list"
              *ngFor="let brand of storeService.store.brands">
              <a class="footer-menu-link"
                [routerLink]="['brand', brand.name, brand.id]">
                {{brand.name}}
              </a>
            </li>
          </ul>
        </li>

        <!-- Contact Us -->
        <li class="footer-menu-title">
          <a tabindex="1">
            Follow Us </a>
          <ul class="footer-list">

            <li class="items-of-list">
              <div class="social">
                <a [href]="configs.social.tiktok"
                  class="pr-3"
                  tabindex="1"
                  target="_self">
                  <fa-icon [icon]="['fab', 'tiktok']"
                    size="lg"></fa-icon></a>
                <a [href]="configs.social.instagram"
                  tabindex="1"
                  class="pr-3"
                  target="_self"><fa-icon [icon]="['fab', 'instagram']"
                    size="lg"></fa-icon></a>
                <a [href]="configs.social.facebook"
                  tabindex="1"
                  target="_self"><fa-icon [icon]="['fab', 'facebook']"
                    size="lg"></fa-icon></a>

              </div>
            </li>
          </ul>
        </li>

      </div>
    </div>
  </div>




  <span class="footer text-center text-white mt-3">
    MARKWINS BEAUTY BRANDS INTERNATIONAL Ltd
  </span>
  <span class="footer text-center text-white">
    Registered Office: c/o RSM UK Tax and Accounting Limited 22 - 25 Farringdon Street, London EC4A 4AB,
    UK
  </span>
  <span class="footer text-center text-white">
    VAT No: GB 745 1590 28
  </span>
  <span class="text-center text-white mt-3 mb-3">
    <a href="https://www.iubenda.com/privacy-policy/{{selectedPrivacyData?.id}}"
      class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
      [title]="selectedPrivacyData?.title">{{selectedPrivacyData?.title}}</a>
    <span class=" ml-2">
      <a href="https://www.iubenda.com/privacy-policy/39692961/cookie-policy"
        class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
        [title]="selectedPrivacyData?.cookieTitle ">{{selectedPrivacyData?.cookieTitle}}</a>
    </span>
  </span>
</footer>