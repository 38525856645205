<div class="sidenav sidenav-sm h-100 ">
  <div class="site__header header pt-0 b-none"
    color="primary">
    <button mat-icon-button
      class="position-absolute right-0 z-1"
      (click)="closeSidenav()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="w-100 d-flex align-items-center justify-content-center">
      <span class="header header--sidenav">
        <img class="logo"
          src="assets/img/logo_markwins.png" />
      </span>
    </div>
  </div>

  <nav id="site-navigation"
    class="header__navigation">
    <ul id="primary-menu"
      class="primary-menu nav-menu">

      <!-- About -->
      <li class="no-link has-mega-menu primary-menu__item">
        <a tabindex="1"
          target="_self">
          About </a>
        <div class="primary-menu__mega-menu">
          <ul class="primary-menu__sub-menu">
            <li class="mega-menu-column primary-menu__sub-item">
              <ul class="primary-menu__column">
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','who-we-are']"
                    target="_self"
                    (click)="closeSidenav()">
                    Who We Are </a>
                </li>
                <li class=" primary-menu__column-item">
                  <a [routerLink]="['about','our-global-footprint']"
                    tabindex="1"
                    target="_self"
                    (click)="closeSidenav()">
                    Our Global Footprint </a>
                </li>
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','culture-values']"
                    target="_self"
                    (click)="closeSidenav()">
                    Culture &amp; Values </a>
                </li>
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','our-partnerships']"
                    target="_self"
                    (click)="closeSidenav()">
                    Our Partnerships </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
      <!-- Brands -->
      <li class="has-mega-menu primary-menu__item">
        <a tabindex="1"
          [routerLink]="['/our-brands']"
          target="_self">
          Our Brands </a>
        <div class="primary-menu__mega-menu">
          <ul class="primary-menu__sub-menu">
            <li class="mega-menu-column primary-menu__sub-item">
              <ul class="primary-menu__column ">
                <li class=" primary-menu__column-item"
                  *ngFor="let brand of brands">
                  <a [routerLink]="['brand', brand.name, brand.id]"
                    (click)="closeSidenav()">
                    {{brand.name}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
      <!-- Contact Us -->
      <li class="social primary-menu__item">
        <a tabindex="1"
          target="_self">
          Contact Us </a>
        <div class="primary-menu__mega-menu">
          <ul class="primary-menu__sub-menu">

            <li class="social-item primary-menu__sub-item">
              <p>Follow Us</p>
              <ul class="social">
                <li><a [href]="configs.social.tiktok"
                    tabindex="1"
                    target="_self"><fa-icon [icon]="['fab', 'tiktok']"
                      size="lg"></fa-icon></a>
                </li>
                <li><a [href]="configs.social.instagram"
                    tabindex="1"
                    target="_self"><fa-icon [icon]="['fab', 'instagram']"
                      size="lg"></fa-icon></a>
                </li>
                <li><a [href]="configs.social.facebook"
                    tabindex="1"
                    target="_self"><fa-icon [icon]="['fab', 'facebook']"
                      size="lg"></fa-icon></a>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </li>
    </ul>
  </nav>
</div>