import { Injectable, Injector } from '@angular/core';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Brand } from '@common/core/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { eStoreActions } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class BrandsService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getBrandById(brandId: string): Observable<Brand> {
    if (this.storeService.store.brands) {
      this.storeService.setStore(store => {
        store.brandId = brandId;
      }, eStoreActions.SET_BRAND_ID);
      return of(this.storeService.store.brands.find(brand => brand.id === brandId));
    } else {
      return this.getBrandListing().pipe(
        map(res => {
          const brands = res.content;
          this.storeService.setStore(store => {
            store.brands = brands;
          }, eStoreActions.SET_BRANDS);
          this.storeService.setStore(store => {
            store.brandId = brandId;
          }, eStoreActions.SET_BRAND_ID);

          return this.storeService.store.brands.find(brand => brand.id === brandId);
        })
      );
    }
  }

  getBrandListing(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Brand[]>> {
    requestParams.modelMapper = 'getBrandListing';
    requestParams.query = gql`
      {
        getBrandListing(first: 10, after: 0, sortBy: "order") {
          edges {
            node {
              id
              code
              name
              subtitle
              description
              logo_white {
                filename
                desktop: fullpath(thumbnail: "logo_desktop")
                desktop_15: fullpath(thumbnail: "logo_desktop_15")
                mobile: fullpath(thumbnail: "logo_mobile")
                mobile_15: fullpath(thumbnail: "logo_mobile_15")
                mimetype
              }
              logo_black {
                filename
                desktop: fullpath(thumbnail: "logo_desktop")
                desktop_15: fullpath(thumbnail: "logo_desktop_15")
                mobile: fullpath(thumbnail: "logo_mobile")
                mobile_15: fullpath(thumbnail: "logo_mobile_15")
                mimetype
              }
              image {
                filename
                desktop: fullpath(thumbnail: "logo_desktop")
                desktop_15: fullpath(thumbnail: "logo_desktop_15")
                mobile: fullpath(thumbnail: "logo_mobile")
                mobile_15: fullpath(thumbnail: "logo_mobile_15")
                mimetype
              }
            }
          }
          totalCount
        }
      }
    `;

    return super.query<Brand[]>(requestParams);
  }
}
