import { Component, Input } from '@angular/core';

@Component({
  selector: 'mks-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonRightText: string;
  @Input() buttonRightRoute: string[];
  constructor() {}
}
