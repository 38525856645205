<div class="site__header header"
  color="primary">
  <button mat-icon-button
    (click)="onToggleMenuButton()"
    *ngIf="storeService.store.activeBreakpoints.isXSmall"
    class="position-absolute left-0 toggle-menu-button">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="w-100 d-flex align-items-center justify-content-center">
    <span>
      <img class="logo pointer"
        [routerLink]="['/']"
        src="assets/img/logo_markwins.png" />
    </span>
  </div>

  <nav id="site-navigation"
    *ngIf="!storeService.store.activeBreakpoints.isXSmall"
    class="header__navigation">
    <ul id="primary-menu"
      class="primary-menu nav-menu">

      <!-- About -->
      <li class="no-link has-mega-menu primary-menu__item">
        <a class="menu-link"
          tabindex="1"
          target="_self">
          About </a>
        <div class="primary-menu__mega-menu">
          <ul class="primary-menu__sub-menu">
            <li class="mega-menu-column primary-menu__sub-item">
              <ul class="primary-menu__column brands-list">
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','who-we-are']"
                    target="_self">
                    Who We Are </a>
                </li>
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','our-global-footprint']"
                    target="_self">
                    Our Global Footprint </a>
                </li>
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','culture-values']"
                    target="_self">
                    Culture &amp; Values </a>
                </li>
                <li class=" primary-menu__column-item">
                  <a tabindex="1"
                    [routerLink]="['about','our-partnerships']"
                    target="_self">
                    Our Partnerships </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
      <!-- Brands -->
      <li class="has-mega-menu primary-menu__item">
        <a tabindex="1"
          [routerLink]="['/our-brands']"
          target="_self">
          Our Brands </a>
        <div class="primary-menu__mega-menu">
          <ul class="primary-menu__sub-menu">
            <li class="mega-menu-column primary-menu__sub-item">
              <ul class="primary-menu__column brands-list">
                <li class=" primary-menu__column-item"
                  *ngFor="let brand of storeService.store.brands">
                  <a class="menu-link"
                    [routerLink]="['brand', brand.name, brand.id]">
                    {{brand.name}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
      <!-- Follow Us -->
      <li class="social primary-menu__item">
        <a tabindex="1"
          target="_self">
          Follow Us </a>
        <div class="primary-menu__mega-menu">
          <ul class="primary-menu__sub-menu">
            <li class="mega-menu-column primary-menu__sub-item">
              <ul class="primary-menu__column">

                <li class="social-item primary-menu__sub-item">

                  <ul class="social">
                    <li><a [href]="configs.social.tiktok"
                        tabindex="1"
                        target="_self">
                        <fa-icon [icon]="['fab', 'tiktok']"
                          size="lg"></fa-icon></a>
                    </li>
                    <li><a [href]="configs.social.instagram"
                        tabindex="1"
                        target="_self"><fa-icon [icon]="['fab', 'instagram']"
                          size="lg"></fa-icon></a>
                    </li>
                    <li><a [href]="configs.social.facebook"
                        tabindex="1"
                        target="_self"><fa-icon [icon]="['fab', 'facebook']"
                          size="lg"></fa-icon></a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</div>