import { AppPathBuilder, AppRequestMapper, AppResponseMapper, AppEnvelopeMapper } from '@web/core/parsers';

import { IBitfGraphQlParserStrategy, IBitfParserStrategy } from '@interfaces';
import {
  BitfPimcoreEnvelopeMapper,
  BitfPimcoreRequestMapper,
  BitfPimcoreResponseMapper,
} from '@common/libs/bitforce/core/parsers/pimcore-parser';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  toastMessage: { durationMs: 5000 },
  parsers: {
    defaultParser: 'appParser',
    defaultGraphQlParser: 'pimcoreParser',
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      pimcoreParser: {
        requestMapper: new BitfPimcoreRequestMapper(),
        responseMapper: new BitfPimcoreResponseMapper(),
        envelopeMapper: new BitfPimcoreEnvelopeMapper(),
      } as IBitfGraphQlParserStrategy,
    },
  },
};
