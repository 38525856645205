import { Asset } from './asset.model';
import { SuperModel } from './super-model.model';

export class Folder extends SuperModel {
  children: Asset[];

  constructor(data: any = {}) {
    super(data);

    if (data.children) {
      this.children = data.children.map(item => new Asset(item));
    } else {
      this.children = [];
    }
  }

  // We do not include readonly fields like inventory, label, description
  get serialised() {
    return {
      id: this.id,
      children: this.children,
    };
  }
}
