import { Component, Input, OnInit } from '@angular/core';
import { Brand } from '@common/core/models';
import { StoreService } from '@common/core/services';

@Component({
  selector: 'mks-brand-block',
  templateUrl: './brand-block.component.html',
  styleUrls: ['./brand-block.component.scss'],
})
export class BrandBlockComponent implements OnInit {
  @Input() brand: Brand;
  @Input() textSide: 'right' | 'left' = 'left';
  @Input() buttonBorderColor = '#000000';
  @Input() marginTop = 80;
  isHovered = false;
  constructor(public storeService: StoreService) {}

  ngOnInit(): void {}

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }
}
