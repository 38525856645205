export enum EApiCallStateNames {
  PRODUCT_LIST = 'PRODUCT_LIST',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  FILTERS = 'FILTERS',
  SORTING = 'SORTING',
}
